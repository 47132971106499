import React from 'react';
import About from 'sections/About/About';
import Contact from 'sections/Contact/Contact';
import Hero from 'sections/Hero/Hero';
import Realizations from 'sections/Realizations/Realizations';
import MainTemplate from 'templates/MainTemplate';

const IndexPage = () => (
  <MainTemplate>
    <Hero />
    <About />
    <Realizations />
    <Contact />
  </MainTemplate>
);

export default IndexPage;
