import React from 'react';
import PropTypes from 'prop-types';
import { Navbar } from 'components/organisms/Navbar/Navbar';
import { Footer } from 'components/organisms/Footer/Footer';
import { GlobalStyle } from '../styles/GlobalStyles';

const MainTemplate = ({ children }) => (
  <>
    <GlobalStyle />
    <Navbar />
    {children}
    <Footer />
  </>
);

MainTemplate.propTypes = {
  children: PropTypes.arrayOf(PropTypes.element).isRequired,
};

export default MainTemplate;
