import React from 'react';
import LaptopImage from 'assets/images/laptop.webp';
import SectionTemplate from 'templates/SectionTemplate';
import { styled } from 'styled-components';
import { minDeviceSize } from 'utils/deviceSize';
import { colors } from 'utils/colors';

const About = () => (
  <SectionTemplate name="about" cols={2}>
    <StyledImage src={LaptopImage} alt="Laptop" loading="lazy" width={350} height={350} />
    <StyledDescriptionWrapper>
      <StyledBrandNameParagraph>DAO project.</StyledBrandNameParagraph>
      <StyledDescriptionHeader>Specjalizujemy się w promocji firm</StyledDescriptionHeader>
      <p>
        Jesteśmy kreatywną, dynamicznie rozwijającą się agencją marketingową, specjalizującą się w
        innowacyjnych rozwiązaniach. Oferujemy tworzenie unikalnej wizualizacji, prowadzenie
        skutecznych kampanii reklamowych oraz optymalizację social mediów. Nasza misja to pomaganie
        klientom w budowaniu silnej marki i osiąganiu sukcesu w środowisku rynkowym.
      </p>
      <br />
      <p>
        Dzięki naszemu kreatywnemu i dostosowanemu do potrzeb klienta podejściu, zwiększamy
        widoczność, pozyskujemy nowych klientów i generujemy wartościowe wyniki. Dodatkowo jesteśmy
        w stanie kompleksowo obsłużyć firmę pod kątem wszelkiego rodzaju gadżetów, materiałów
        szkoleniowych, wizytówek itp. Zaufaj nam, aby podnieść swoją firmę na wyższy poziom!
      </p>
    </StyledDescriptionWrapper>
  </SectionTemplate>
);

const StyledImage = styled.img`
  width: 100%;
  height: 100%;
`;

const StyledDescriptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const StyledBrandNameParagraph = styled.p`
  color: ${colors.green};
  text-transform: uppercase;
  font-weight: 700;
`;

const StyledDescriptionHeader = styled.h2`
  @media ${minDeviceSize.tablet} {
    font-size: 2.25rem;
    line-height: 2.5rem;
  }

  @media ${minDeviceSize.phone} {
    font-size: 1.875rem;
    line-height: 2.25rem;
  }

  font-size: 1.5rem;
  line-height: 2rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;

  font-weight: 700;
`;

export default About;
