import React from 'react';
import PropTypes from 'prop-types';
import { colors } from 'utils/colors';
import { styled } from 'styled-components';
import { minDeviceSize } from 'utils/deviceSize';

const SectionTemplate = ({ name, secondary, cols, children, gap }) => (
  <StyledSectionWrapper name={name} secondary={secondary}>
    <StyledSectionContainer gap={gap} cols={cols}>
      {children}
    </StyledSectionContainer>
  </StyledSectionWrapper>
);

SectionTemplate.propTypes = {
  name: PropTypes.string,
  secondary: PropTypes.string,
  cols: PropTypes.number.isRequired,
  children: PropTypes.arrayOf(PropTypes.element).isRequired,
  gap: PropTypes.number,
};

SectionTemplate.defaultProps = {
  name: '#',
  secondary: 'false',
  gap: 0,
};

const StyledSectionContainer = styled.div`
  max-width: 1240px;
  margin-left: auto;
  margin-right: auto;
  display: grid;

  @media ${minDeviceSize.tablet} {
    grid-template-columns: ${({ cols }) => `repeat(${cols}, minmax(0, 1fr))`};
    gap: ${({ gap }) => `${gap}rem`};
  }
`;

const StyledSectionWrapper = styled.div`
  width: 100%;
  padding-top: ${({ secondary }) => (secondary === 'true' ? '10rem' : '4rem')};
  padding-bottom: ${({ secondary }) => (secondary === 'true' ? '10rem' : '4rem')};
  padding-left: 1rem;
  padding-right: 1rem;
  background-color: ${({ secondary }) => (secondary === 'true' ? colors.black : colors.white)};
`;

export default SectionTemplate;
