import realizationDataProvider from 'services/RealizationProvider';

const breakpoints = [1080, 640, 384, 256, 128, 96, 64, 48];

const photos = realizationDataProvider().map((realization) => ({
  src: realization.imageSrc,
  width: 1080,
  height: realization.height,
  alt: realization.description,
  srcSet: breakpoints.map((breakpoint) => {
    const height = Math.round((1080 / 610) * breakpoint);
    return {
      src: realization.imageSrc,
      width: breakpoint,
      height,
    };
  }),
}));

export default photos;
