import { styled } from 'styled-components';
import { colors } from 'utils/colors';
import PropTypes from 'prop-types';

export const ButtonLink = ({ Icon, to, secondary, text, grid }) => (
  <StyledButton href={to} secondary={secondary} grid={grid}>
    <StyledIconWrapper>
      <Icon />
    </StyledIconWrapper>

    {text}
  </StyledButton>
);

ButtonLink.propTypes = {
  Icon: PropTypes.func.isRequired,
  to: PropTypes.string.isRequired,
  secondary: PropTypes.string,
  text: PropTypes.string.isRequired,
  grid: PropTypes.string,
};

ButtonLink.defaultProps = {
  secondary: 'false',
  grid: 'false',
};

const StyledIconWrapper = styled.div`
  font-size: 24px;
`;

const StyledButton = styled.a`
  text-decoration: none;
  background-color: ${({ secondary }) => (secondary === 'true' ? colors.black : colors.green)};
  color: ${({ secondary }) => (secondary === 'true' ? colors.green : colors.black)};
  width: 200px;
  border-radius: 0.375rem;
  font-weight: 700;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  margin-left: auto;
  margin-right: auto;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  cursor: pointer;
  text-align: center;
  display: ${({ grid }) => (grid === 'true' ? 'grid' : 'flex')};
  justify-content: ${({ grid }) => (grid === 'true' ? 'center' : 'space-evenly')};
  justify-items: center;

  &:hover {
    transition-duration: 500ms;
    scale: 1.05;
    box-shadow: 0px 11px 17px -5px rgba(66, 68, 90, 1);
  }
`;
