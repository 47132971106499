import React from 'react';
import EventImage from 'assets/images/event.jpg';
import ProjectingImage from 'assets/images/projecting.jpg';
import SocialMediaImage from 'assets/images/social-media.jpg';
import SectionTemplate from 'templates/SectionTemplate';
import { styled } from 'styled-components';
import ImageCard from 'components/atoms/ImageCard/ImageCard';
import { ButtonPageLink } from 'components/atoms/ButtonPageLink/ButtonPageLink';

const Realizations = () => (
  <SectionTemplate secondary="true" name="realizations" cols={3} gap={2}>
    <ImageCard src={EventImage} title="Eventy" alt="Eventy" />
    <ImageCard src={SocialMediaImage} title="Social media" alt="Social media" />
    <ImageCard src={ProjectingImage} title="Projekty" alt="Projektowanie" />
    <div />
    <StyledButtonWrapper>
      <ButtonPageLink
        to="/realizacje"
        aria-label="Pokaż więcej realizacji"
        text="Zobacz nasze realizacje"
      />
    </StyledButtonWrapper>
    <div />
  </SectionTemplate>
);

const StyledButtonWrapper = styled.div`
  max-width: 1240px;
  text-align: center;
  font-size: 20px !important;
`;

export default Realizations;
