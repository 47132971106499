import React from 'react';
import { styled } from 'styled-components';
import PropTypes from 'prop-types';
import { colors } from 'utils/colors';

const ImageCard = ({ src, title, alt, secondary }) => (
  <StyledImageCardWrapper>
    <img src={src} width={390} height={260} alt={alt} loading="lazy" />
    <StyledImageCardDescription secondary={secondary}>{title}</StyledImageCardDescription>
  </StyledImageCardWrapper>
);

ImageCard.propTypes = {
  title: PropTypes.string.isRequired,
  src: PropTypes.string.isRequired,
  alt: PropTypes.string,
  secondary: PropTypes.string,
};

ImageCard.defaultProps = {
  alt: '#',
  secondary: 'false',
};

const StyledImageCardWrapper = styled.div`
  background-color: ${colors.black};
  transition-duration: 500ms;
  border-radius: 0.5rem;
  box-shadow: 0px 11px 14px -9px rgba(255, 255, 255, 1);
  margin-bottom: 2rem;
  padding: ${({ secondary }) => (secondary === 'true' ? '1rem' : '0')};
  &:hover {
    scale: 1.05;
    box-shadow: 0px 11px 16px -5px rgba(255, 255, 255, 1);
  }
`;

const StyledImageCardDescription = styled.h2`
  font-size: ${({ secondary }) => (secondary === 'true' ? '1rem' : '1.5rem')};
  line-height: 2rem;
  font-weight: 700;
  text-align: center;
  padding-top: 2rem;
  padding-bottom: 2rem;
  color: ${colors.white};
`;

export default ImageCard;
