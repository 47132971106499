import React from 'react';
import { styled } from 'styled-components';
import { BrandName } from 'components/atoms/BrandName/BrandName';
import { colors } from 'utils/colors';
import { Link } from 'react-scroll';

export const SideNavbar = () => {
  const closeSideNavbar = () => {
    const hamburgerButton = document.querySelector('#hamburger-button');
    if (hamburgerButton !== null) {
      hamburgerButton.click();
    }
  };

  return (
    <StyledLeftNavbarContainer>
      <StyledBrandName>DAO project.</StyledBrandName>
      <StyledNavbarListContainer>
        <StyledNavbarBorderItem
          to="about"
          spy
          smooth
          offset={50}
          duration={500}
          isDynamic
          onClick={closeSideNavbar}
        >
          O nas
        </StyledNavbarBorderItem>
        <StyledNavbarBorderItem
          to="realizations"
          spy
          smooth
          offset={50}
          duration={500}
          isDynamic
          onClick={closeSideNavbar}
        >
          Realizacje
        </StyledNavbarBorderItem>
        <StyledNavbarItem
          to="contact"
          spy
          smooth
          offset={50}
          duration={500}
          isDynamic
          onClick={closeSideNavbar}
        >
          Kontakt
        </StyledNavbarItem>
      </StyledNavbarListContainer>
    </StyledLeftNavbarContainer>
  );
};

const StyledBrandName = styled(BrandName)`
  margin: 1.5rem;
`;

const StyledNavbarListContainer = styled.ul`
  text-transform: uppercase;
  padding: 1rem;
  display: flex;
  flex-direction: column;
`;

const StyledNavbarBorderItem = styled(Link)`
  border-color: ${colors.gray};
  padding: 1rem;
  cursor: pointer;

  &:hover {
    color: ${colors.green};
  }
`;

const StyledNavbarItem = styled(Link)`
  padding: 1rem;
  cursor: pointer;

  &:hover {
    color: ${colors.green};
  }
`;

const StyledLeftNavbarContainer = styled.div`
  @keyframes slideX {
    0% {
      transform: translateX(-100%);
    }
    100% {
      transform: translateX(0);
    }
  }

  position: fixed;
  left: 0px;
  top: 0px;
  width: 60%;
  height: 100%;
  z-index: 999;
  border-right-width: 1px;
  border-right-color: rgb(243 244 246);
  animation: slideX 0.3s ease-in-out;
  background-color: ${colors.lightBlack};
`;
