import { BrandName } from 'components/atoms/BrandName/BrandName';
import React from 'react';
import { FaInstagram, FaFacebookSquare } from 'react-icons/fa';
import { styled } from 'styled-components';
import { colors } from 'utils/colors';

export const Footer = () => (
  <StyledFooterWrapper>
    <div>
      <BrandName>DAO project.</BrandName>
      <StyledParagraph>Rozwiń z nami swój biznes.</StyledParagraph>
      <StyledSocialLinkWrapper>
        <a
          className="hover:text-[#00df9a] mr-5"
          href="https://www.facebook.com/profile.php?id=100094951668906"
          target="_blank"
          rel="noreferrer"
          aria-label="Przejdź na profil facebook"
        >
          <FaFacebookSquare size={30} />
        </a>
        <a
          className="hover:text-[#00df9a]"
          href="https://www.instagram.com/_daoproject/"
          target="_blank"
          rel="noreferrer"
          aria-label="Przejdź na profil instagram"
        >
          <FaInstagram size={30} />
        </a>
      </StyledSocialLinkWrapper>
    </div>
    <StyledDescriptionWrapper>
      <div>
        <StyledParagraph>Rozwiązania</StyledParagraph>
        <ul>
          <StyledDescriptionLink>Projektowanie</StyledDescriptionLink>
          <StyledDescriptionLink>Marketing</StyledDescriptionLink>
          <StyledDescriptionLink>Social media</StyledDescriptionLink>
        </ul>
      </div>
    </StyledDescriptionWrapper>
  </StyledFooterWrapper>
);

const StyledParagraph = styled.p`
  padding-top: 1rem;
  padding-bottom: 1rem;
  color: ${colors.white};
`;

const StyledDescriptionLink = styled.li`
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  font-size: 0.875rem;
  line-height: 1.25rem;
`;

const StyledDescriptionWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const StyledSocialLinkWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 15%;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  color: ${colors.white};
`;

const StyledFooterWrapper = styled.div`
  max-width: 1240px;
  margin-left: auto;
  margin-right: auto;
  padding-top: 4rem;
  padding-bottom: 4rem;
  padding-left: 1rem;
  padding-right: 1rem;
  display: flex;
  justify-content: space-around;
  color: ${colors.mediumGray};
`;
