import React, { useState } from 'react';
import { AiOutlineClose, AiOutlineMenu } from 'react-icons/ai';
import { styled } from 'styled-components';
import { minDeviceSize } from 'utils/deviceSize';
import { TopNavbar } from 'components/molecules/TopNavbar/TopNavbar';
import { SideNavbar } from 'components/molecules/SideNavbar/SideNavbar';

export const Navbar = () => {
  const [toggleNav, setToggleNav] = useState(false);

  const handleToggleNav = () => {
    setToggleNav(!toggleNav);
  };

  return (
    <StyledNavbarWrapper>
      <TopNavbar />
      <StyledHamburgerButton
        id="hamburger-button"
        type="button"
        onClick={handleToggleNav}
        aria-label="Otwórz panel nawigacyjny"
      >
        {toggleNav === true ? <AiOutlineClose size={20} /> : <AiOutlineMenu size={20} />}
      </StyledHamburgerButton>
      {toggleNav && <SideNavbar />}
    </StyledNavbarWrapper>
  );
};
const StyledNavbarWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 6rem;
  max-width: 1240px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 1rem;
  padding-right: 1rem;
  color: white;
`;

const StyledHamburgerButton = styled.button`
  display: block;
  @media ${minDeviceSize.tablet} {
    display: none;
  }
`;
