import { BrandName } from 'components/atoms/BrandName/BrandName';
import React from 'react';
import { styled } from 'styled-components';
import { colors } from 'utils/colors';
import { minDeviceSize } from 'utils/deviceSize';
import { Link } from 'react-scroll';

export const TopNavbar = () => (
  <>
    <BrandName>DAO project.</BrandName>
    <StyledNavbarListContainer>
      <StyledNavbarListItem href="#" to="about" spy smooth offset={50} duration={500} isDynamic>
        O nas
      </StyledNavbarListItem>
      <StyledNavbarListItem
        href="#"
        to="realizations"
        spy
        smooth
        offset={50}
        duration={500}
        isDynamic
      >
        Realizacje
      </StyledNavbarListItem>
      <StyledNavbarListItem href="#" to="contact" spy smooth offset={50} duration={500} isDynamic>
        Kontakt
      </StyledNavbarListItem>
    </StyledNavbarListContainer>
  </>
);

const StyledNavbarListContainer = styled.div`
  display: none;
  @media ${minDeviceSize.tablet} {
    display: flex;
  }
`;

const StyledNavbarListItem = styled(Link)`
  padding: 0 1rem 0 1rem;
  cursor: pointer;

  &:hover {
    color: ${colors.green};
  }
`;
