import React from 'react';
import Typed from 'react-typed';
import { styled } from 'styled-components';
import { colors } from 'utils/colors';
import { minDeviceSize } from 'utils/deviceSize';
import { Button } from 'components/atoms/Button/Button';

const Hero = () => (
  <SyledHeroWrapper>
    <StyledParagraphTop>AGENCJA REKLAMOWA, KTÓRA SPROSTA TWOIM OCZEKIWANIOM</StyledParagraphTop>
    <StyledHeading>Rozwiń z nami swój biznes</StyledHeading>
    <StyledTypedParagraphContainer>
      <StyledParagraphTyped>Czym się zajmujemy i co możemy dla Ciebie zrobić?</StyledParagraphTyped>
    </StyledTypedParagraphContainer>
    <StyledTypedParagraphContainer>
      <StyledTyped
        strings={[
          'wizytówki',
          'długopisy',
          'smycze',
          'medale',
          'flagi',
          'kalendarze wszelkiego rodzaju',
          'fototapety',
          'obsługa social mediów',
          'wydruki na:',
          'kamieniu',
          'kamieniu',
          'plexi',
          'płycie osb',
        ]}
        typeSpeed={60}
        backSpeed={80}
        loop
      />
    </StyledTypedParagraphContainer>
    <StyledTypedBottom>... i wiele więcej</StyledTypedBottom>
    <StyledParagraphBottom>
      Nasz zakres usług kończy się tam, gdzie kończą się potrzeby i oczekiwania naszego klienta.
    </StyledParagraphBottom>
    <Button to="contact" aria-label="Przejdź do sekcji kontakt" text="Skontaktuj się znami" />
  </SyledHeroWrapper>
);

const SyledHeroWrapper = styled.div`
  max-width: 800px;
  margin-top: -96px;
  width: 100%;
  height: 100vh;
  text-align: center;
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  color: ${colors.white};
  margin-left: auto;
  margin-right: auto;
`;

const StyledParagraphTop = styled.p`
  text-transform: uppercase;
  color: ${colors.green};
  font-weight: bold;
  padding: 0.5rem;
`;

const StyledHeading = styled.h2`
  @media ${minDeviceSize.tablet} {
    font-size: 4.5rem;
    line-height: 1;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }
  @media ${minDeviceSize.phone} {
    font-size: 3.75rem;
    line-height: 1;
  }
  font-size: 2.25rem;
  line-height: 2.5rem;
  font-weight: 700;
`;

const StyledTypedParagraphContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledParagraphTyped = styled.p`
  @media ${minDeviceSize.tablet} {
    font-size: 1.875rem;
    line-height: 2.25rem;
  }
  @media ${minDeviceSize.phone} {
    font-size: 1.5rem;
    line-height: 2rem;
  }
  font-size: 0.85rem;
  line-height: 1rem;
  font-weight: 700;
`;

const StyledTyped = styled(Typed)`
  @media ${minDeviceSize.tablet} {
    font-size: 1.875rem;
    line-height: 2.25rem;
    padding-left: 1rem;
  }
  @media ${minDeviceSize.phone} {
    font-size: 1.5rem;
    line-height: 2rem;
  }
  font-size: 0.85rem;
  line-height: 1rem;
  padding-left: 0.5rem;
  font-weight: 700;
`;

const StyledTypedBottom = styled.p`
  @media ${minDeviceSize.tablet} {
    font-size: 1.875rem;
    line-height: 2.25rem;
    padding-left: 1rem;
  }
  @media ${minDeviceSize.phone} {
    font-size: 1.5rem;
    line-height: 2rem;
  }
  font-size: 0.85rem;
  line-height: 1rem;
  padding-left: 0.5rem;
  font-weight: 700;
  color: ${colors.white};
`;

const StyledParagraphBottom = styled.p`
  @media ${minDeviceSize.tablet} {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }
  font-size: 1.125rem;
  line-height: 1.75rem;
  font-weight: 700;
  color: ${colors.mediumGray};
`;

export default Hero;
