import React from 'react';
import { BackNavbar } from 'components/organisms/BackNavbar/BackNavbar';
import { Footer } from 'components/organisms/Footer/Footer';
import PhotoAlbum from 'react-photo-album';
import photos from 'assets/photos';
import { styled } from 'styled-components';
import { colors } from 'utils/colors';

const RealizationsPage = () => (
  <>
    <BackNavbar />
    <StyledSectionWrapper>
      <PhotoAlbum layout="columns" photos={photos} />
    </StyledSectionWrapper>
    <Footer />
  </>
);

const StyledSectionWrapper = styled.div`
  width: 100%;
  padding-top: 4rem;
  padding-bottom: 4rem;
  padding-left: 1rem;
  padding-right: 1rem;
  background-color: ${colors.white};
`;

export default RealizationsPage;
