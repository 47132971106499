import { styled } from 'styled-components';
import { colors } from 'utils/colors';
import { Link } from 'react-scroll';
import PropTypes from 'prop-types';

export const Button = ({ to, secondary, text }) => (
  <StyledButton
    href="#"
    to={to}
    secondary={secondary}
    type="button"
    spy
    smooth
    offset={50}
    duration={500}
    isDynamic
  >
    {text}
  </StyledButton>
);

Button.propTypes = {
  to: PropTypes.string.isRequired,
  secondary: PropTypes.string,
  text: PropTypes.string.isRequired,
};

Button.defaultProps = {
  secondary: 'false',
};

const StyledButton = styled(Link)`
  background-color: ${({ secondary }) => (secondary === 'true' ? colors.black : colors.green)};
  color: ${({ secondary }) => (secondary === 'true' ? colors.green : colors.black)};
  width: 200px;
  border-radius: 0.375rem;
  font-weight: 700;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  margin-left: auto;
  margin-right: auto;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  cursor: pointer;
  text-align: center;

  &:hover {
    transition-duration: 500ms;
    scale: 1.05;
    box-shadow: 0px 11px 17px -5px rgba(66, 68, 90, 1);
  }
`;
