import React from 'react';
import { styled } from 'styled-components';
import { ButtonPageLink } from 'components/atoms/ButtonPageLink/ButtonPageLink';
import { BrandName } from 'components/atoms/BrandName/BrandName';

export const BackNavbar = () => (
  <StyledNavbarWrapper>
    <BrandName>DAO project.</BrandName>
    <StyledButtonPageLink to="/" text="Powrót do strony głównej" />
  </StyledNavbarWrapper>
);

const StyledButtonPageLink = styled(ButtonPageLink)`
  padding: 0.5rem !important;
`;

const StyledNavbarWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 6rem;
  max-width: 1240px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 1rem;
  padding-right: 1rem;
  color: white;
`;
