import React from 'react';
import AnalizeImage from 'assets/images/kontakt.png';
import SectionTemplate from 'templates/SectionTemplate';
import { ButtonLink } from 'components/atoms/ButtonLink/ButtonLink';
import { styled } from 'styled-components';
import { colors } from 'utils/colors';
import { minDeviceSize } from 'utils/deviceSize';
import { AiOutlinePhone, AiOutlineMail } from 'react-icons/ai';

const Contact = () => (
  <SectionTemplate name="contact" cols={2}>
    <img src={AnalizeImage} alt="Kobieta analityk" width={500} height={280} loading="lazy" />
    <StyledContactContainer>
      <StyledBrandNameParagraph>DAO project.</StyledBrandNameParagraph>
      <StyledDescriptionHeader>Skontaktuj się z nami</StyledDescriptionHeader>
      <StyledContactButtonsWrapper>
        <ButtonLink
          Icon={AiOutlinePhone}
          secondary="true"
          to="tel:607 436 091"
          aria-label="Zadzwoń na numer 607 436 091"
          text="607 436 091"
          grid="true"
        />
        <ButtonLink
          Icon={AiOutlineMail}
          secondary="true"
          to="mailto:kontakt@daoproject.pl"
          aria-label="Napisz wiadomość e-mail: biuro@dao.gmail.com"
          text="kontakt@daoproject.pl"
          grid="true"
        />
      </StyledContactButtonsWrapper>
    </StyledContactContainer>
  </SectionTemplate>
);

const StyledContactButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  @media ${minDeviceSize.tablet} {
    flex-direction: row;
  }
`;

const StyledContactContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const StyledBrandNameParagraph = styled.p`
  color: ${colors.green};
  text-transform: uppercase;
  font-weight: 700;
`;

const StyledDescriptionHeader = styled.h2`
  @media ${minDeviceSize.tablet} {
    font-size: 2.25rem;
    line-height: 2.5rem;
  }

  @media ${minDeviceSize.phone} {
    font-size: 1.875rem;
    line-height: 2.25rem;
  }

  font-size: 1.5rem;
  line-height: 2rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;

  font-weight: 700;
`;

export default Contact;
