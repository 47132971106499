import React from 'react';
import IndexPage from 'pages/IndexPage';
import { Routes, Route } from 'react-router-dom';
import RealizationsPage from 'pages/RealizationsPage';
import NotFoundPage from 'pages/NotFoundPage';

const App = () => (
  <Routes>
    <Route index path="/" element={<IndexPage />} />
    <Route path="/realizacje" element={<RealizationsPage />} />
    <Route path="*" element={<NotFoundPage />} />
  </Routes>
);

export default App;
